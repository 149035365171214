import './MechanicsTable.scss';

import React, { ReactNode } from 'react';
import DataTable from 'react-data-table-component';
import { DeepPartial } from 'react-hook-form';
import { SystemUser } from 'src/generated/graphql';
import LoaderLinearDiscrete from 'src/modules/Common/Common/LoaderLinearDiscrete';

import { NoResult } from '../../../Layout/QuickSearch/components/Dialog/Panels/QuickSearch';
import { MechanicsTableColumns } from '../../columns/MechanicsTableColumns';

interface Props {
    rowClickedAction: (row: SystemUser) => ReactNode;
    mechanics: DeepPartial<SystemUser>[] | [];
    loading?: boolean;
    actionName?: string;
}

export const MechanicsTable = ({ rowClickedAction, mechanics, actionName, loading = false }: Props) => {
    return (
        <>
            <LoaderLinearDiscrete visible={loading} />
            <DataTable
                columns={[...MechanicsTableColumns, {
                    name: actionName,
                    selector: '',
                    sortable: true,
                    cell: (row: SystemUser) => rowClickedAction(row),
                }]}
                data={mechanics as SystemUser[]}
                onRowClicked={(row) => {
                    rowClickedAction(row as SystemUser);
                }}
                striped
                noDataComponent={
                    <NoResult>Hittade inga mekaniker för butiken</NoResult>
                }
            />
        </>
    );
};
