import { useMutation } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import styled from '@emotion/styled';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import LoaderCircular from '../../Common/Common/LoaderCircular';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import TextInput from '../../Common/Form/TextInput';
import { inputStyles, useStyles } from '../../Common/styles/inputStyles';
import { CREATE_CUSTOMER_BIKE } from '../graphql/mutations';
import { transformToCreateCustomerBikeInput } from '../utils/utils';

const StyledButtonContainer = withStyles({
    root: {
        marginTop: '20px',
        marginBottom: '12px',
        width: inputStyles.inputWidth,
    },
})(Grid);

const Wrapper = styled.div`
    position: relative;
`;

const BikeForm = ({ onCreate, customerId, companyId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [
        createCustomerBike,
        { loading: creatingCustomerBike, error },
    ] = useMutation(CREATE_CUSTOMER_BIKE, {
        onCompleted: () =>
            enqueueSnackbar('Cykeln är nu tillagd!', {
                variant: 'success',
            }),
        onError: () =>
            enqueueSnackbar('Kunde inte skapa cykel!', {
                variant: 'error',
            }),
    });

    const { control, register, handleSubmit } = useForm({
        // defaultValues: {
        //     frameNumber: 'A-10006',
        //     manufacturer: 'Crescent',
        //     description: 'En beskrivning',
        //     purchaseDate: new Date(2012, 11, 1),
        //     sku: 'YGS-108',
        //     yearModel: new Date(),
        // },
        defaultValues: {
            frameNumber: '',
            manufacturer: '',
            description: '',
            purchaseDate: new Date(2020, 1, 1),
            sku: '',
            tag: '',
            yearModel: new Date(),
        },
    });

    const onSubmit = async (values) => {
        const createCustomerBikeInput = transformToCreateCustomerBikeInput(
            values,
            customerId,
            companyId,
        );
        const createdBikeData = await createCustomerBike({
            variables: { createCustomerBikeInput },
        }).catch();

        if (createdBikeData) {
            onCreate(createdBikeData.data.createCustomerBike);
        }
    };

    const classes = useStyles();

    return (
        <Wrapper>
            <LoaderCircular visible={creatingCustomerBike} />
            <ErrorMessage error={error} />
            <Container maxWidth='xs'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction='column' justify='space-around'>
                        <TextInput
                            id='frameNumberInput'
                            label='Ramnummer'
                            name='frameNumber'
                            register={register}
                            margin='normal'
                        />
                        <TextInput
                            id='manufacturerInput'
                            label='Märke'
                            name='manufacturer'
                            register={register}
                            margin='normal'
                        />
                        <TextInput
                            id='descriptionInput'
                            label='Modell'
                            name='description'
                            register={register}
                            margin='normal'
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Controller
                                name='purchaseDate'
                                control={control}
                                render={(props) => (
                                    <KeyboardDatePicker
                                        {...props}
                                        disableToolbar
                                        className={classes.inputStyles}
                                        format='yyyy-MM-dd'
                                        id='purchaseDateInput'
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        label='Inköpsdatum'
                                        margin='normal'
                                        fullWidth
                                        inputVariant='outlined'
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                        <TextInput
                            id='skuInput'
                            label='Artikelnummer'
                            name='sku'
                            register={register}
                            margin='normal'
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Controller
                                name='yearModel'
                                control={control}
                                render={(props) => (
                                    <KeyboardDatePicker
                                        {...props}
                                        animateYearScrolling
                                        className={classes.inputStyles}
                                        disableToolbar
                                        id='yearPicker'
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        label='Årsmodell'
                                        margin='normal'
                                        inputVariant='outlined'
                                        fullWidth
                                        views={['year']}
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                        {companyId
                            ? (<TextInput
                                id='tagInput'
                                label='Märkning'
                                name='tag'
                                register={register}
                                margin='normal'
                            />)
                            : undefined
                        }
                        <StyledButtonContainer container justify='flex-end'>
                            <Button
                                type='submit'
                                variant='contained'
                                className={classes.buttonStyles}
                                startIcon={
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                }
                            >
                                Lägg till cykel
                            </Button>
                        </StyledButtonContainer>
                    </Grid>
                </form>
            </Container>
        </Wrapper>
    );
};

export default BikeForm;
