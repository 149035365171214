import './ProductSelector.scss';

import React from 'react';

import {
    Product,
    useGetFavouritesQuery,
} from '../../../../../../../generated/graphql';
import { useStore } from '../../../../../../Common/context/store-context';
import { Selector } from './Selector';

export const FavouriteProductSelector = ({ categoryId, onSelect }: { categoryId?: number, onSelect: (row: Product) => void }) => {
    const [storeId] = useStore();

    const {
        data,
        loading,
        previousData
    } = useGetFavouritesQuery({
        variables: {
            storeId,
            categoryId: categoryId === 53696 ? null : categoryId,
        },
        fetchPolicy: 'cache-and-network',
    });

    const products = (data || previousData)?.favourites;

    return (
        <div className='card product-selector' data-testid='product-selector'>
            <Selector
                storeId={storeId}
                categoryId={categoryId}
                onSelect={onSelect}
                products={products?.items}
                loading={loading}
            />
        </div>
    );
};
