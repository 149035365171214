import { useMutation } from '@apollo/client';
import { faEye, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useSnackbar } from 'notistack';
import React from 'react';
import { HeadsLog, Mutation, MutationResendHeadsMessageArgs, OrderByDirection } from 'src/generated/graphql';
import LoaderCircular, { LoaderWrapper } from 'src/modules/Common/Common/LoaderCircular';
import LoaderLinearDiscrete from 'src/modules/Common/Common/LoaderLinearDiscrete';
import { useDialog2 } from 'src/modules/Common/custom-hooks/useDialog2';
import AppButton from 'src/modules/Common/Form/AppButton';
import { formatDateTime } from 'src/modules/Common/helpers/dates';
import { HeadsLogStatus } from 'src/modules/HeadsIntegration/HeadsLog/HeadsLogStatus';

import { orderStatusText } from '../../ServiceOrder/utils/serviceOrderFields';
import { GET_HEADS_LOGS, RESEND_HEADS_MESSAGE } from '../graphql/HeadsLogsGql';
import { useTableQuery } from '../useTableQuery';
import { HeadsLogDialog } from './HeadsLogDialog';

export function HeadsLogTable() {
    const { data, page, setPage, pending, refetch } = useTableQuery(GET_HEADS_LOGS, {
        page: 0,
        orderDir: OrderByDirection.Desc,
        orderBy: 'createdAt',
    });
    const { enqueueSnackbar } = useSnackbar();

    const [resend, {
        loading: loadingResend,
    }] = useMutation<Mutation, MutationResendHeadsMessageArgs>(RESEND_HEADS_MESSAGE);

    const { createDialog } = useDialog2();

    const handleShow = (row: HeadsLog) => {
        createDialog((ref: any) => <HeadsLogDialog dialogRef={ref} headsLog={row} />);
    };

    const handleResend = (row: HeadsLog) => {
        resend({
            variables: { id: row.id },
        })
            .then(() => refetch())
            .catch(() => enqueueSnackbar('kunde inte skicka igen', { variant: 'error' }));
    };

    if (!data) {
        return <LoaderWrapper><LoaderCircular size={50} /></LoaderWrapper>;
    }

    const list = data.headsLogs;

    return (
        <div>
            <LoaderLinearDiscrete visible={pending} />
            <LoaderCircular visible={loadingResend} />
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tid</TableCell>
                            <TableCell>Ordernr</TableCell>
                            <TableCell>Orderstatus</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell width="240" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.items.map((row) => (
                            <TableRow
                                key={row.id}
                            >
                                <TableCell>{formatDateTime(row.createdAt)}</TableCell>
                                <TableCell>{row.serviceOrder.orderNumber}</TableCell>
                                <TableCell>{orderStatusText(row.serviceOrder.status)}</TableCell>
                                <TableCell>{row.path}</TableCell>
                                <TableCell><HeadsLogStatus status={row.status} /></TableCell>
                                <TableCell>
                                    <div className="button-wrapper button-wrapper-no-margin">
                                        <AppButton
                                            size="small"
                                            onClick={() => handleShow(row)}
                                            startIcon={
                                                <FontAwesomeIcon icon={faEye} />
                                            }
                                        >
                                            Visa
                                        </AppButton>
                                        {row.path === 'hips/api/storm/orders' ? (
                                            <AppButton
                                                size="small"
                                                onClick={() => handleResend(row)}
                                                startIcon={
                                                    <FontAwesomeIcon icon={faRedo} />
                                                }
                                            >
                                                Skicka igen
                                            </AppButton>
                                        ) : null}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                page={page}
                count={list.total}
                rowsPerPage={list.pageSize!}
                rowsPerPageOptions={[]}
                onChangePage={(e, p) => setPage(p)}
            />
        </div>
    );
}
