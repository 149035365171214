import './ProductSelector.scss';

import TablePagination from '@material-ui/core/TablePagination';
import React, { useState } from 'react';

import {
    Product,
    useGetProductsQuery,
} from '../../../../../../../generated/graphql';
import { useStore } from '../../../../../../Common/context/store-context';
import { Selector } from './Selector';

type ProductSelectorProps = {
    categoryId: number,
    onSelect: (row: Product) => void,
}

const ProductSelector = ({
    categoryId,
    onSelect,
}: ProductSelectorProps) => {
    const [page, setPage] = useState(0);

    const [storeId] = useStore();

    const {
        data,
        loading,
        previousData,
    } = useGetProductsQuery({
        variables: {
            page: page + 1,
            categoryId,
            storeId,
        },
    });

    const products = (data || previousData)?.products;

    return (
        <div className='card product-selector' data-testid='product-selector'>
            <Selector
                storeId={storeId}
                categoryId={categoryId}
                onSelect={onSelect}
                products={products?.items}
                loading={loading}
            />
            <TablePagination
                component='div'
                count={products?.total || 0}
                page={page}
                onChangePage={(e, p) => setPage(p)}
                rowsPerPage={products?.items?.length || 0}
                rowsPerPageOptions={[]}
            />
        </div>
    );
};

export default ProductSelector;
