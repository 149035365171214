import './SmsStatisticsTable.scss';

import { useQuery } from '@apollo/client';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Query, SmsStatisticsStats } from 'src/generated/graphql';
import LoaderCircular, { LoaderWrapper } from 'src/modules/Common/Common/LoaderCircular';
import { MonoSpacedNumber } from 'src/modules/Common/Common/MonoSpacedNumber';
import ErrorMessage from 'src/modules/Common/ErrorMessage/ErrorMessage';
import { SMS_STATISTICS } from 'src/modules/SmsOvererview/graphql/SmsStatsGql';

function Stats({ stats }: { stats: SmsStatisticsStats }) {
    return (
        <div>
            <div className='successes'>
                <MonoSpacedNumber number={stats.successes} />
            </div>
            <div className='errors'>
                <MonoSpacedNumber number={stats.errors} />
            </div>
        </div>
    );
}

export function SmsStatisticsTable() {

    const { data, error } = useQuery<Query>(SMS_STATISTICS, {
        nextFetchPolicy: 'cache-and-network',
    });

    if (error) {
        return <ErrorMessage error={error} />
    }

    if (!data) {
        return <LoaderWrapper><LoaderCircular /></LoaderWrapper>
    }

    const rows = data.smsStatistics.items.sort((a, b) => {
        return ('' + a.store?.name).localeCompare('' + b.store?.name);
    });

    return (
        <TableContainer className='sms-statistics-table'>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Butik</TableCell>
                        <TableCell align="right">Senaste 30 dagarna</TableCell>
                        <TableCell align="right">Senaste 90 dagarna</TableCell>
                        <TableCell align="right">Totalt</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={i}
                        >
                            <TableCell>
                                {row.store?.name || '-'}
                            </TableCell>
                            <TableCell align="right">
                                <Stats stats={row.stats.find((s) => s.key === 'last30Days')!} />
                            </TableCell>
                            <TableCell align="right">
                                <Stats stats={row.stats.find((s) => s.key === 'last90Days')!} />
                            </TableCell>
                            <TableCell align="right">
                                <Stats stats={row.stats.find((s) => s.key === 'total')!} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={99} align="right">
                            <span className='successes'>*Skickade</span>
                            <span className='errors'>*Misslyckade</span>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
