import { useSnackbar } from 'notistack';

import { GetFavouritesDocument, useToggleFavouriteMutation } from '../../../../../../../generated/graphql';
import { useCategory } from '../useCategory.hook';


export const useSelector = (storeId: number, categoryId?: number) => {
    const { enqueueSnackbar } = useSnackbar();
    const { defaultCategory } = useCategory();

    const [ toggleFavourite ] = useToggleFavouriteMutation({
        refetchQueries: [
            {
                query: GetFavouritesDocument,
                variables: {
                    storeId, categoryId: categoryId === defaultCategory.Id ? null : categoryId,
                }
            }
        ],
        onCompleted: (data) => {
            if (data.toggleFavourite.IsFavourite) {
                enqueueSnackbar('Serviceprodukten har favoritmarkerats', { variant: 'success' });
            } else {
                enqueueSnackbar('Serviceprodukten har avmarkerats', { variant: 'success' });
            }
        },
        onError: () => {
            enqueueSnackbar(`
                Din butik kan ha max 20 favoritmarkerade serviceprodukter. 
                Ta bort en existerande favoritmarkering för att lägga till en ny.
            `, { variant: 'error' });
        },
    });

    const onToggleFavourite = async (sku: string) => {
        return toggleFavourite({
            variables: {
                storeId,
                sku,
            },
        });
    }

    return {
        onToggleFavourite
    };
}
