import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import React, { useState } from 'react';

import {
    Product,
} from '../../../../../../../generated/graphql';
import LoaderCircular from '../../../../../../Common/Common/LoaderCircular';
import { priceFormat, vatFormat } from '../../../../../utils/productHelper';
import { useSelector } from './useSelector.hook';


type SelectorProps = {
    storeId: number,
    categoryId?: number,
    products?: Product[],
    loading?: boolean,
    onSelect: (row: Product) => void,
};

const status = (statusId?: number) => {
    switch (statusId) {
        case 1:
            return 'Aktiv';
        case 2:
            return 'Kommande';
        case 3:
            return 'Utgående';
        case 4:
            return 'Dold';
        case 5:
            return 'Inaktiv';
        case 6:
            return 'Ej aktiverad';
        default:
            return '';
    }
}

const CellOverflow = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 520px;
`;

export const Selector = ({
    storeId,
    categoryId,
    products,
    loading,
    onSelect,
}: SelectorProps) => {
    const [selected, setSelected] = useState<number>();

    const { onToggleFavourite } = useSelector(storeId, categoryId);

    const onClick = (row: Product, i: number) => {
        onSelect(row);
        setSelected(i);
        setTimeout(() => setSelected(undefined), 300);
    }

    function priceTooltip(row: Product) {
        return priceFormat(row.Price, 2) + ' + ' + vatFormat(row.Vat);
    }

    return (
        <>
            <LoaderCircular visible={loading} />
            <TableContainer>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>ArtNr</TableCell>
                            <TableCell>Beskrivning</TableCell>
                            <TableCell align='right'>Pris</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products?.map((row, i) => (
                            <TableRow
                                key={i}
                                onClick={() => onClick(row, i)}
                                selected={selected === i}
                            >
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={async (event) => {
                                            event.stopPropagation();

                                            if (row.PartNo) {
                                                await onToggleFavourite(row.PartNo);
                                            }
                                        }}
                                    >
                                        {row.IsFavourite && (
                                            <StarIcon sx={{ color: '#ffcb05' }} />
                                        )}
                                        {!row.IsFavourite && (
                                            <StarOutlineIcon />
                                        )}
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={status(row.StatusId || undefined)} placement='left'>
                                        <CellOverflow>{row.PartNo}</CellOverflow>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <CellOverflow>{row.Name}</CellOverflow>
                                </TableCell>
                                <TableCell align='right'>
                                    <Tooltip title={priceTooltip(row)} placement='left'>
                                        <span>{priceFormat(row.PriceIncVat)}</span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
