import { useMutation } from '@apollo/client';
import {
    Mutation,
    MutationAddSystemUserToGroupArgs, MutationAddUserToStoreArgs,
    MutationCreateUserArgs,
    MutationCreateUserForStoreArgs,
    MutationDeleteUserArgs, MutationEditUserArgs, MutationEditUserOnStoreArgs,
    MutationRemoveSystemUserFromGroupArgs, MutationRemoveUserFromStoreArgs, MutationSetPasswordArgs,
} from 'src/generated/graphql';
import {
    ADD_SYSTEM_USER_TO_GROUP, ADD_USER_TO_STORE,
    CREATE_USER,
    CREATE_USER_FOR_STORE,
    DELETE_USER, EDIT_STORE_ON_USER, EDIT_USER,
    REMOVE_SYSTEM_USER_FROM_GROUP, REMOVE_USER_FROM_STORE, SET_PASSWORD,
} from 'src/modules/UserAdministration/graphql/UsersGql';

export function useCreateUserMutation() {
    return useMutation<Mutation, MutationCreateUserArgs>(CREATE_USER);
}

export function useEditStoreOnUser() {
    return useMutation<Mutation, MutationEditUserOnStoreArgs>(EDIT_STORE_ON_USER);
}

export function useAddUserToStoreMutation() {
    return useMutation<Mutation, MutationAddUserToStoreArgs>(ADD_USER_TO_STORE);
}

export function useRemoveUserFromStoreMutation() {
    return useMutation<Mutation, MutationRemoveUserFromStoreArgs>(REMOVE_USER_FROM_STORE);
}

export function useEditUserMutation() {
    return useMutation<Mutation, MutationEditUserArgs>(EDIT_USER);
}

export function useCreateUserForStoreMutation() {
    return useMutation<Mutation, MutationCreateUserForStoreArgs>(CREATE_USER_FOR_STORE);
}

export function useAddSystemUserToGroupMutation() {
    return useMutation<Mutation, MutationAddSystemUserToGroupArgs>(ADD_SYSTEM_USER_TO_GROUP);
}

export function useRemoveSystemUserFromGroupMutation() {
    return useMutation<Mutation, MutationRemoveSystemUserFromGroupArgs>(REMOVE_SYSTEM_USER_FROM_GROUP);
}

export function useDeleteUserMutation() {
    return useMutation<Mutation, MutationDeleteUserArgs>(DELETE_USER);
}

export function useSetPasswordMutation() {
    return useMutation<Mutation, MutationSetPasswordArgs>(SET_PASSWORD);
}

