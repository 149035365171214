

export const useCategory = () => {
    return {
        defaultCategory: {
            Id: 53696,
            Code: 'Verkstad_Arbete',
        }
    }
}
