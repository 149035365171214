import './ProductCategorySelector.scss';

import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import React, { useEffect, useState } from 'react';

import { useGetCategoriesQuery } from '../../../../../../../generated/graphql';
import LoaderCircularDiscrete from '../../../../../../Common/Common/LoaderCircularDiscrete';
import { useCategory } from '../useCategory.hook';

const ProductCategorySelector = ({ onChange }) => {
    const [categoryPath, setCategoryPath] = useState([]);
    let categoryId = categoryPath[categoryPath.length - 1] || null;
    const parentId = categoryPath[categoryPath.length - 2] || null;

    const { data } = useGetCategoriesQuery();

    const { defaultCategory } = useCategory();

    const items = data ? data.productCategories.items.filter((cat) => {
        if (cat.ParentCategoryId === cat.CategoryId) {
            if (cat.Hierarchy !== 1) {
                return false; // Some categories have a copy inside itself
            }
        }

        return true;
    }).map((category) => ({
        ...category,
        ParentCategoryId: category.ParentCategoryId === category.CategoryId && category.Hierarchy === 1
            ? null
            : category.ParentCategoryId
    })) : [];

    const findCategory = (id) => {
        return items.find((cat) => cat.CategoryId === id);
    }

    const select = (cat) => {
        categoryPath.push(cat.CategoryId);
        setCategoryPath([...categoryPath]);
    };

    const back = () => {
        categoryPath.pop();
        setCategoryPath([...categoryPath]);
    };

    let currentCategory = categoryId ? findCategory(categoryId) : null;
    let parentCategory = parentId ? findCategory(parentId) : null;

    // Sets the default category
    // Remove this code if the top level should be root
    if (!categoryId) {
        const defaultCat = items.find((cat) => {
            return cat.Category.Id === defaultCategory.Id; // prod
        });

        if (defaultCat) {
            categoryId = defaultCat.CategoryId;
        }
    }

    const children = items.filter((cat) => cat.ParentCategoryId === categoryId);

    useEffect(() => {
        onChange(categoryId);
    }, [categoryId, onChange]);

    if (!data) {
        return <LoaderCircularDiscrete />;
    }

    return (
        <div className='product-categories'>
            <h4>
                {currentCategory ? (
                    <div>Kategori: {currentCategory.Category.DefaultName} {currentCategory.Category.Id}</div>
                ) : (
                    <div>Kategorier</div>
                )}
            </h4>
            <div className='scroll'>
                <div className='items'>
                    {currentCategory && !parentCategory && (
                        <div
                            className='item'
                            role='presentation'
                            onClick={() => back()}
                        >
                            <div>
                                <ArrowBackOutlined fontSize='small' />
                                <span>Översikt</span>
                            </div>
                        </div>
                    )}
                    {parentCategory && (
                        <div
                            className='item'
                            role='presentation'
                            onClick={() => back()}
                        >
                            <div>
                                <ArrowBackOutlined fontSize='small' />
                                <span>
                                    Tillbaka till{' '}
                                    {parentCategory.Category.DefaultName}
                                </span>
                            </div>
                        </div>
                    )}
                    {children.map((category) => (
                        <div
                            className='item'
                            role='presentation'
                            key={category.CategoryId}
                            onClick={() => select(category)}
                        >
                            <div>
                                <FolderOutlinedIcon fontSize='small' />
                                <span>{category.Category.DefaultName}</span>
                                {/*<span>{category.CategoryId}</span>*/}
                                {/*<span>{category.ParentCategoryId}</span>*/}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductCategorySelector;
