import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Box, Checkbox, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DeepPartial } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { SubStore, useSubStoresQuery } from 'src/generated/graphql';
import { useSubStore } from 'src/modules/Common/context/sub-store-context';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppSearchDebounce from 'src/modules/Common/Form/AppSearchDebounce';

import LoaderCircularDiscrete from '../../Common/Common/LoaderCircularDiscrete';
import { useStore } from '../../Common/context/store-context';
import { SubStoreListColumns } from '../columns/SubStorePageColumns';
import SubStoreTableMenu from './SubStoreTableMenu';

const SubStoreList = () => {
    const [ storeId,, storeList, ] = useStore();
    const { setSubStores } = useSubStore();

    const [subStoreList, setSubStoreList] = useState<SubStoreRowType[]>([])

    const [searchTerm, setSearchterm] = useState('');

    const [filteredSubStoreList, setFilteredSubStoreList] = useState<SubStoreRowType[]>([])

    const [showArchivedLists, setShowArchivedLists] = useState(false);

    const [showAll, setShowAll] = useState(false);

    const { data, loading, refetch } = useSubStoresQuery({
        onCompleted: (data) => {
            setSubStoreList(data?.subStores?.map((subStore) => SubStoreToListType(subStore)) ?? [])
            setSubStores(data?.subStores || [])
        },
        fetchPolicy: 'network-only',
        variables: {
            stores: showAll ? storeList.map((store) => store.id) : [storeId],
        },
    });

    useEffect(() => {
        setFilteredSubStoreList(subStoreList.filter((subStore) => {
            const term = searchTerm?.toLocaleLowerCase();

            return !searchTerm
                || subStore.name?.toLocaleLowerCase().includes(term)
                || subStore.admin?.toLocaleLowerCase().includes(term)
                || subStore.butik?.toLocaleLowerCase().includes(term)
                || subStore.status?.toLocaleLowerCase().includes(term);
        }).filter((subStore) => {
            return showArchivedLists || subStore.status !== 'ARCHIVED';
        }));

    }, [searchTerm, subStoreList, showArchivedLists]);

    useEffect(() => {
        refetch();
    }, [refetch, data?.subStores]);

    const history = useHistory();

    const openStore = (id: number) => {
        history.push(`/sub-store/${id}`)
    }

    return (
        <Stack
            direction="column"
            sx={{
                width: "100%",
            }}
            gap={2}
        >
            <Typography color='textSecondary'>
                Hantera inställningar för bokningslistan
            </Typography>
            <Stack direction="row" gap={2}>
                <AppButton
                    icon={faPlus}
                    onClick={() => history.push('/sub-store')}
                >
                    Ny Bokningslista
                </AppButton>
                <Tooltip
                    title="Visa alla listor för dina butiker."
                    placement="top"
                    arrow
                >
                    <label className="subStoreListCheckbox" htmlFor='showAllCheckbox'>
                        <Checkbox id='showAllCheckbox' checked={showAll} onChange={() => setShowAll((prevState) => !prevState)} />
                        <Typography color="textSecondary">
                            Visa alla bokningslistor
                        </Typography>
                    </label>
                </Tooltip>
                <label className="subStoreListCheckbox" htmlFor='archivedCheckbox'>
                    <Checkbox id='archivedCheckbox' checked={showArchivedLists} onChange={() => setShowArchivedLists((prevState) => !prevState)} />
                    <Typography color="textSecondary">
                        Visa arkiverade bokningslistor
                    </Typography>
                </label>
            </Stack>
            <Box>
                <AppSearchDebounce
                    autoSearch
                    delay={50}
                    id="shareowners-search"
                    onSearch={setSearchterm}
                    placeholder="Sök namn / butik / admin / status"
                />
            </Box>
            {loading && <LoaderCircularDiscrete />}
            <DataTable
                className="sub-store-list-table"
                columns={[
                    ...SubStoreListColumns,
                    {
                        name: 'Onlinebokningslänk',
                        selector: 'onlineBookingUrl',
                        sortable: false,
                        grow: 3,
                    },
                    {
                        name: '',
                        selector: 'actions',
                        sortable: false,
                        cell: (row: SubStoreRowType) => {
                            return (<SubStoreTableMenu subStore={row} openStore={openStore} refetch={refetch} />)
                        }
                    }
                ]}
                data={filteredSubStoreList}
                pagination
                striped
            />
        </Stack>
    );
};

type SubStoreRowType = {
    id: number;
    name: string;
    butik: string;
    admin: string;
    status: string;
    onlineBookingUrl: string;
};

function SubStoreToListType(subStore: DeepPartial<SubStore>): SubStoreRowType {
    return {
        id: subStore?.id ?? 0,
        name: subStore?.name ?? '',
        butik: subStore.store?.name ?? '',
        admin: subStore?.adminUser?.name ?? '',
        status: subStore?.status ?? '',
        onlineBookingUrl: subStore?.onlineBookingUrl || '',
    };
}

export default SubStoreList;
