import { useLazyQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { css } from 'emotion';
import React from 'react';
import DataTable from 'react-data-table-component';

import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useDialog3 } from '../../Common/custom-hooks/useDialog3';
import AppSearchDebounce from '../../Common/Form/AppSearchDebounce';
import { colors } from '../../Common/styles/colors';
import CreateCustomerDialog from '../../Customer/CustomerDialogs/CreateCustomerDialog';
import { NoResult } from '../../Layout/QuickSearch/components/Dialog/Panels/QuickSearch';
import { SEARCH_CUSTOMER } from '../graphql/queries';

const TableWrapper = styled.div`
    position: relative;
    margin: 30px 0 15px;
`;

const Container = styled.div`
    box-sizing: border-box;
    margin-top: 30px;
    padding: 0 24px;
    width: 100%;
`;

const customerTableStyles = css`
    border: 2px solid ${colors.grayScale[1]};
    border-radius: 5px;
    overflow-x: auto;
    min-width: 100%;
`;

const SearchCustomer = ({ onSelectCustomer }) => {
    const [searchCustomer, { data, loading, called }] = useLazyQuery(SEARCH_CUSTOMER, {
        fetchPolicy: 'network-only',
    });

    // @todo Do we need to handle create company customer here too?

    const { createDialog } = useDialog3();
    const openCustomerCreateDialog = () => {
        const ref = createDialog((ref) => (
            <CreateCustomerDialog dialogRef={ref} />
        ));
        ref.onClose.push(onSelectCustomer);
    };

    const columns = [
        {
            name: 'Kundnummer',
            selector: 'id',
            grow: 0,
            sortable: true,
        },
        {
            name: 'Namn',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Telefonnummer',
            cell: ({ phoneNumber, cellPhoneNumber }) =>
                phoneNumber ? phoneNumber : cellPhoneNumber,
        },
        {
            name: 'E-post',
            selector: 'email',
            sortable: true,
        },
        {
            button: true,
            cell: (customer) => (
                <Button
                    onClick={() => onSelectCustomer(customer)}
                    variant='contained'
                    color='primary'
                >
                    Välj
                </Button>
            ),
        },
    ];

    const items = data?.searchForCustomers.items || [];

    return (
        <Container>
            <AppSearchDebounce
                id='searchCustomerId'
                label='Sökord'
                onSearch={(value) => value && searchCustomer({ variables: { query: value } })}
            />
            <TableWrapper>
                <LoaderCircular visible={loading} size={50} />
                {called && (
                    <div className={customerTableStyles}>
                        <DataTable
                            noHeader
                            columns={columns}
                            defaultSortField="id"
                            data={items}
                            striped
                            noDataComponent={(<NoResult>Hittade inga kunder</NoResult>)}
                        />
                    </div>
                )}
            </TableWrapper>
            <div className="button-wrapper-right">
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                    onClick={openCustomerCreateDialog}
                >
                    Lägg till ny kund
                </Button>
            </div>
        </Container>
    );
};

export default SearchCustomer;
