import React from 'react';

import {
    CompanyCustomerSearchResult,
    useSearchForCompanyCustomersLazyQuery,
} from '../../../../../../generated/graphql';
import { useDialog3 } from '../../../../../Common/custom-hooks/useDialog3';
import EditCustomerDialog from '../../../../../Customer/CustomerDialogs/EditCustomerDialog';
import { QuickSearch } from './QuickSearch';

const columns = [
    {
        name: 'ID',
        selector: 'customerId',
        sortable: true,
        grow: 2,

    },
    {
        name: 'Företagsnamn',
        selector: 'name',
        cell: (row: CompanyCustomerSearchResult) => row.name || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Org.nummer',
        selector: 'orgNumber',
        cell: (row: CompanyCustomerSearchResult) => row.orgNumber || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'E-post',
        selector: 'email',
        cell: (row: CompanyCustomerSearchResult) => row.email || '-',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Tel.',
        selector: 'phoneNumber',
        cell: (row: CompanyCustomerSearchResult) => row.phoneNumber || '-',
        width: '140px',
        sortable: true,
        grow: 3,
    },
];

export function CompanyCustomerSearchPanel() {
    const { createDialog } = useDialog3();

    const [
        getCustomers,
        { data,
            loading,
            error,
            called,
        }
    ] = useSearchForCompanyCustomersLazyQuery({
        fetchPolicy: 'network-only',
    });

    const onSearch = async (query: string) => {
        if (query && query.length > 1) {
            await getCustomers({ variables: { query } });
        }
    };

    const onSelect = (customerId: number) => {
        createDialog((ref) => (
            <EditCustomerDialog customerId={customerId} dialogRef={ref} />
        ));
    };

    return (
        <QuickSearch
            error={error}
            id='customer-search'
            label='Sök'
            placeholder='Org.nummer eller företagsnamn'
            onSearch={onSearch}
            loading={loading}
            columns={columns}
            items={data?.searchForCompanyCustomers?.items}
            onSelect={onSelect}
            onSelectColumnName={'customerId'}
            called={called}
            noResultText={'Inga kunder hittades'}
            debounceDelay={600}
        />
    );
}
