import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Stack } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
    Mutation,
    MutationSendCustomerSmsArgs,
    OrderByDirection,
    ServiceOrder,
    useGetCustomerByIdQuery,
    useGetSmsRecordsQuery,
    useGetSmsTemplateLazyQuery,
    useGetSmsTemplatesQuery,
} from 'src/generated/graphql';
import LoaderCircular, { LoaderWrapper } from 'src/modules/Common/Common/LoaderCircular';
import LoaderCircularDiscrete from 'src/modules/Common/Common/LoaderCircularDiscrete';
import { useInterval } from 'src/modules/Common/custom-hooks/useInterval';
import useIsMounted from 'src/modules/Common/custom-hooks/useIsMounted';
import { usePaginationArguments } from 'src/modules/Common/custom-hooks/usePaginationArguments';
import AlignItemsList from 'src/modules/ServiceOrder/components/Dialogs/ServiceOrderDialog/SmsTab/SmsList';
import SmsSendMessage from 'src/modules/ServiceOrder/components/Dialogs/ServiceOrderDialog/SmsTab/SmsSendMessage';
import { SEND_CUSTOMER_SMS } from 'src/modules/ServiceOrder/graphql/SmsRecordGql';

import { formatDate } from '../../../../../Common/helpers/dates';

interface Props {
    serviceOrder: ServiceOrder
}

type MutationArgs = Partial<MutationSendCustomerSmsArgs>;

export default function SmsTab({ serviceOrder }: Props) {
    const mounted = useIsMounted();

    // @todo Here we fetch the CUSTOMER by id, may need to handle Company some way too.
    const { data: customerData } = useGetCustomerByIdQuery({
        variables: { id: serviceOrder.customerId || 0 },
    });

    const [pagination, setPagination] = usePaginationArguments({ pageSize: 20, orderDir: OrderByDirection.Asc });

    // Query to get records
    const { data, refetch } = useGetSmsRecordsQuery({
        variables: { serviceOrderId: serviceOrder.id, pagination },
        nextFetchPolicy: 'network-only',
    });

    const loadMore = () => {
        if(data?.smsRecords.hasMore) {
            setPagination({
                ...pagination,
                pageSize: (pagination?.pageSize ?? 20) + 20,
            });
        }
    };

    // Send new sms
    const [postNewMessage, { loading: sending }] = useMutation<Mutation, MutationArgs>(SEND_CUSTOMER_SMS, {
        variables: { serviceOrderId: serviceOrder.id },
    });

    // Function to refetch only if still mounted
    const safeRefetch = () => mounted && refetch?.();

    // Refetch whenever there are pending texts

    let intervals = 0;
    const refreshAfterIntervals = 10;

    useInterval(() => {
        // It's a bad hack but since react-tabs seems not to work well according to existing comments,
        // this at least prevents sms spam leading to backend failures
        // now we won't fetch new sms'es if sms tab is not open
        if (intervals < refreshAfterIntervals) {
            intervals++;
        } else if (
            intervals === refreshAfterIntervals &&
            document.querySelector('.dialog2-selected-tab[data-tab-text="Sms"]')
        ) {
            intervals = 0;
            safeRefetch();
        }
    }, 250);

    const methods = useForm();

    const [onGetTemplate] = useGetSmsTemplateLazyQuery({
        onCompleted: (data) => {
            methods.setValue('message', data.template?.message || '');
        }
    })

    const { data: templatesData, loading } = useGetSmsTemplatesQuery()

    const templates = templatesData?.templates || [];

    const onClick = (template: string) => {
        onGetTemplate({
            variables: {
                input: {
                    serviceOrderId: serviceOrder.id,
                    templateKey: template,
                    date: formatDate(serviceOrder.serviceDate),
                    externalKey: serviceOrder.externalKey || '',
                }
            }
        });
    }

    const onNewMessage = async (message: string) => {
        await postNewMessage({ variables: { message } });
        refetch();
        
        setTimeout(safeRefetch, 1000);
        setTimeout(safeRefetch, 5000);
    };

    if (!data || loading) {
        return <LoaderWrapper><LoaderCircular size={30} /></LoaderWrapper>;
    }

    const items = data.smsRecords.items;

    return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', position: 'absolute', gap: '10px' }}>
                {templates.map(({ template, label }, index) => (
                    <Button
                        key={index}
                        onClick={() => onClick(template || '')}
                        variant='contained'
                        style={{ backgroundColor: '#000', color: '#fff' }}
                    >
                        {label}
                    </Button>
                ))}
            </Box>
            <Card className="service-order-sms-tab">
                {sending && <LoaderCircularDiscrete />}
                <CardContent>
                    <Stack
                        justifyContent="space-between"
                        direction="row"
                        sx={{
                            paddingBottom: '10px',
                        }}
                    >
                        <Box>Från {serviceOrder.conversation?.fromNumber}</Box>
                        <Box>Till kund {serviceOrder.conversation?.toNumber}</Box>
                    </Stack>
                    <AlignItemsList
                        items={items}
                        loadMore={loadMore}
                        hasMore={data.smsRecords.hasMore}
                    />
                    {customerData?.customer?.consentSms ? (
                        <SmsSendMessage
                            onNewMessage={onNewMessage}
                            serviceOrder={serviceOrder}
                            methods={methods}
                        />
                    ) : (
                        <div className="sms-not-allowed">
                            Kund har ej accepterat meddelanden via sms.
                        </div>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}
