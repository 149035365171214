import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { OrderByDirection, PaginatedArguments, Query } from 'src/generated/graphql';
import useIsMounted from 'src/modules/Common/custom-hooks/useIsMounted';

export function useTableQuery<TVariables>(
    query: DocumentNode | TypedDocumentNode<Query, TVariables>,
    pagination: PaginatedArguments = { page: 0 },
    options: QueryHookOptions<Query, TVariables> = {},
) {
    const [paginationState, setPagination] = useState<PaginatedArguments>(pagination);
    const [pending, setPending] = useState(false);
    const mounted = useIsMounted();

    const functions = useQuery<Query, TVariables | any>(query, {
        ...options || {},
        variables: {
            pagination: pagination,
            ...options?.variables || {},
        },
    });

    const patchPagination = async (props: Partial<PaginatedArguments>) => {
        const newPagination = { ...paginationState, ...props };
        setPagination(newPagination);
        setPending(true);
        await functions.refetch({ pagination: newPagination });
        if (mounted) {
            setPending(false);
        }
    };

    return {
        ...functions,
        pending,
        page: paginationState.page!,
        pageSize: paginationState.pageSize!,
        orderBy: paginationState.orderBy!,
        orderDir: paginationState.orderDir!,
        setPage: (v: number) => patchPagination({ page: v }),
        setPageSize: (v: number) => patchPagination({ pageSize: v }),
        setOrderBy: (v: string) => patchPagination({ orderBy: v }),
        setOrderDir: (v: OrderByDirection) => patchPagination({ orderDir: v }),
    };
}
